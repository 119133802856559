import { FormattedMessage } from "react-intl";
import { TableInput } from "../../shared";
import {
  WorkOrderCostCenterFragment,
  ItemKind,
  InventoryStatus,
  WorkOrderVariantFragment,
  RecipeVariant,
} from "../../../lib/graphql";
import {
  formatUnitValue,
  formatProfitableName,
  formatCostCenterName,
  variantSelectDropdown,
  formatVariantLink,
  formatInventoryRequestLink,
  formatUnitValueConversion,
  shouldUpdateBy,
  shouldUpdate,
} from "../../../lib/formats";

import { useContext } from "react";
import {
  useApplicationUnitOptions,
  useStockVariantOptions,
  useStockVariantWithRecipesOptions,
} from "../../../lib/hooks/inventory/variants";
import { Form, InputNumber, NamePath, Rules } from "../../form";
import { Typography } from "antd";
import { WorkOrderContext } from "./WorkOrderContext";
import { ColumnsType } from "antd/lib/table";
import { UnitSelect } from "../../units";
import { useCurrentUser } from "../../../lib/hooks";
import { WorkOrderVariant } from "./builder/tools";
import {
  CurrentStockTooltip,
  InventoryRequestWarehouseCell,
} from "./inventory";
import { filterColumns } from "../../../lib/utils";
import { flatMap } from "lodash";

function shouldUpdateInputs(prev: any, next: any) {
  return (
    prev.costCenters.filter(Form.undestroyed).length !==
      next.costCenters.filter(Form.undestroyed).length ||
    prev.inputs.length !== next.inputs.length ||
    prev.costCenters.findIndex(
      (c: WorkOrderCostCenterFragment, index: number) => {
        const cc = next.costCenters[index];
        return c.dayGoal !== cc?.dayGoal;
      }
    ) >= 0
  );
}

export function isInputReadonly(input: WorkOrderVariantFragment) {
  return (
    input.status !== InventoryStatus.NotRequested &&
    input.status !== InventoryStatus.Requesting
  );
}

export function RateInput({
  inputIndex,
  name,
  onChange,
  hideType,
}: {
  inputIndex: number;
  name: NamePath;
  hideType?: boolean;
  onChange: (val?: number | null) => void;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const input = builder.inputs.getBy(inputIndex);
  const { currentTenant } = useCurrentUser();

  const options = useApplicationUnitOptions({
    water: builder.isWaterUsage,
    unit: builder.allowProgress
      ? workOrder.activity.progressUnit
      : builder.isAgricultural
      ? workOrder.locality.areaUnit
      : undefined,
    plant:
      builder.isAgricultural && !!currentTenant.features.customCropVarieties,
  });

  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdateBy((p) => p.inputs[inputIndex])}
    >
      {() => {
        if (builder.isReadonly || isInputReadonly(input)) {
          return formatUnitValue(
            builder.form.getFieldValue(name),
            builder.form.getFieldValue(["inputs", inputIndex, "unit"]),
            workOrder.activity.progressUnit.abbr,
            { maximumFractionDigits: 4 }
          );
        }

        return (
          <Form.Item compact name={name} rules={[Rules.gtEqZero]}>
            <InputNumber
              min={0}
              step={0.1}
              precision={4}
              onChange={onChange}
              addonAfter={
                <UnitSelect.FormItem
                  name={["inputs", inputIndex, "unit"]}
                  unitTypes={[input.variant.variationUnit.unitType]}
                  onUnitChange={() => onChange()}
                />
              }
              per={
                hideType ? undefined : (
                  <Form.Item
                    name={["inputs", inputIndex, "applicationUnitId"]}
                    compact
                  >
                    <UnitSelect
                      allowClear={false}
                      showSearch={false}
                      style={{ width: "100px" }}
                      options={options}
                      onUnitChange={(unit) => {
                        builder.form.setFieldValue(
                          ["inputs", inputIndex, "applicationUnit"],
                          unit
                        );
                        onChange();
                      }}
                    />
                  </Form.Item>
                )
              }
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}

export function WorkOrderInputs({ readonly }: { readonly: boolean }) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();
  const progressUnit = workOrder.activity.progressUnit;

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdateInputs}>
      {({ getFieldValue }) => {
        if (!readonly) {
          builder.inputs.initInputs();
        }

        const costCenterColumns = (
          variant: WorkOrderVariantFragment,
          variantIndex: number
        ) => {
          const columns: ColumnsType<WorkOrderCostCenterFragment> = [
            {
              dataIndex: "name",
              render: (_, cc) => (
                <>
                  {formatProfitableName(cc)}
                  <br />
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {builder.allowDosage ? (
                      formatCostCenterName(cc.costCenter.parentCostCenter)
                    ) : (
                      <span>
                        <FormattedMessage id="workOrders.dayGoal" />:{" "}
                        {formatUnitValue(cc.dayGoal, progressUnit)}
                      </span>
                    )}
                  </Typography.Text>
                </>
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="dosage.perCropField"
                  defaultMessage="Dosage per Crop Field"
                />
              ),
              align: "center",
              width: "14rem",
              render: (_, _cc, index) => (
                <RateInput
                  name={builder.costCenters.variantFieldName(
                    index,
                    variant,
                    "dosage"
                  )}
                  inputIndex={variantIndex}
                  hideType
                  onChange={(val) =>
                    builder.inputs.calculateAmountByCostCenterDosage(
                      index,
                      variantIndex,
                      val
                    )
                  }
                />
              ),
            },
            {
              title: (
                <FormattedMessage id="subtotal" defaultMessage="subtotal" />
              ),
              align: "center",
              width: 230,
              render: (_, _cc, index) => {
                const name = builder.costCenters.variantFieldName(
                  index,
                  variant,
                  "amount"
                );
                return (
                  <Form.Item
                    noStyle
                    shouldUpdate={shouldUpdateBy((p) => p.inputs[variantIndex])}
                  >
                    {() => {
                      const amount = getFieldValue(name);
                      const unit = getFieldValue([
                        "inputs",
                        variantIndex,
                        "unit",
                      ]);

                      return formatUnitValueConversion(amount, {
                        unit,
                        conversionUnit: variant.variant.variationUnit,
                      });
                    }}
                  </Form.Item>
                );
              },
            },
          ];
          return columns;
        };

        const columns: ColumnsType<WorkOrderVariant> = filterColumns([
          {
            title: <FormattedMessage id="variants.entityName" />,
            dataIndex: "variant",
            render: (_, v) => formatVariantLink(v.variant),
          },
          currentTenant.features.inventoryStock && {
            width: "1rem",
            render: (_, v) => (
              <CurrentStockTooltip
                variant={v}
                date={workOrder.documentDate}
                localityId={workOrder.locality.id}
              />
            ),
          },
          {
            title: <FormattedMessage id="dosage" />,
            dataIndex: "dosage",
            width: "22rem",
            align: "center",
            render: (_, _v, index) => (
              <RateInput
                name={["inputs", index, "dosage"]}
                inputIndex={index}
                onChange={() => builder.inputs.calculateAmounts(index)}
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="workOrders.totalRequested"
                defaultMessage="totalRequested"
              />
            ),
            dataIndex: "totalAmount",
            width: "15rem",
            align: "center",
            render: (_, v, index) => (
              <Form.Item
                noStyle
                shouldUpdate={shouldUpdateBy((p) => p.inputs[index])}
              >
                {() => {
                  const totalAmount = getFieldValue([
                    "inputs",
                    index,
                    "totalAmount",
                  ]);
                  const unit = getFieldValue(["inputs", index, "unit"]);

                  return formatUnitValueConversion(totalAmount, {
                    unit,
                    conversionUnit: v.variant.variationUnit,
                  });
                }}
              </Form.Item>
            ),
          },
          currentTenant.inventoryRequestEnabled && {
            title: <FormattedMessage id="workOrders.inventoryStatus" />,
            width: 185,
            align: "center",
            dataIndex: "status",
            render: (_, v) =>
              formatInventoryRequestLink(workOrder.id, v.status),
          },
        ]);

        return (
          <TableInput
            name="inputs"
            rules={[Rules.required]}
            dataSource={workOrder.inputs}
            tableProps={{
              bordered: true,
              expandable: {
                rowExpandable: (variant) =>
                  builder.costCenters.hasItems && Form.undestroyed(variant),

                expandedRowRender: (variant, variantIndex) => {
                  return (
                    <TableInput
                      name="costCenters"
                      dataSource={workOrder.costCenters}
                      hideMenu
                      tableProps={{
                        size: "small",
                      }}
                      rowKey={(c) => c.costCenter.id + c.profitableId}
                      columns={costCenterColumns(variant, variantIndex)}
                    />
                  );
                },
              },
            }}
            rowKey={(f) => f.variant.id}
            disabled={readonly}
            onRemove={(v) => builder.inputs.onRemove(v)}
            allowBulkRemove
            tableSelectProps={{
              mode: "multiple",
              optionsHook: useStockVariantOptions,
              optionsHookParams: {
                variables: {
                  localityId: currentTenant.multifarmInventoryEnabled
                    ? undefined
                    : workOrder.locality.id,
                  date: workOrder.documentDate,
                  filter: { itemKind: [ItemKind.Consumable] },
                },
              },
              placeholder: (
                <FormattedMessage
                  id="select.variants"
                  defaultMessage="variants"
                />
              ),
              dropdownRender: variantSelectDropdown({
                hideRight: !currentTenant.features.inventoryStock,
              }),
              entityById: (_, { variant }) => {
                if (variant) {
                  return {
                    id: "",
                    variant,
                    totalAmount: 0,
                    status: InventoryStatus.NotRequested,
                    unit: variant.variationUnit,
                  };
                }
              },
            }}
            addSorter={(a, b) => builder.inputs.sorter(a, b)}
            columns={columns}
          />
        );
      }}
    </Form.Item>
  );
}

export function WorkOrderWaterInputs() {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();

  return (
    <TableInput
      name="inputs"
      rules={[Rules.required]}
      dataSource={workOrder.inputs}
      tableProps={{
        bordered: true,
      }}
      rowKey={(f) => f.variant.id}
      disabled={builder.isReadonly || builder.isDataIntake}
      onRemove={(v) => builder.waterUsage.onRemoveInput(v)}
      allowBulkRemove
      tableSelectProps={{
        mode: "multiple",
        showGroups: true,
        groupsSorter: () => 0,
        optionsHook: useStockVariantWithRecipesOptions,
        optionsHookParams: {
          variables: {
            localityId: workOrder.locality.id,
            date: workOrder.documentDate,
            fetchEquipment: true,
            filter: { itemKind: [ItemKind.Consumable] },
          },
        },
        placeholder: (
          <FormattedMessage id="select.variants" defaultMessage="variants" />
        ),
        dropdownRender: variantSelectDropdown(),
        entityById: (_, { variant, recipe }) => {
          if (variant) {
            return {
              id: "",
              variant,
              totalAmount: 0,
              dosage: 0,
              status: InventoryStatus.NotRequested,
              unit: variant.variationUnit,
              applicationUnit: builder.waterUsage.value.waterUnit,
              applicationUnitId: builder.waterUsage.value.waterUnit.id,
            };
          }

          if (recipe) {
            return recipe.variants.map((v: RecipeVariant) => ({
              id: "",
              variant: v.variant,
              totalAmount: 0,
              dosage: v.rate,
              status: InventoryStatus.NotRequested,
              unit: v.unit,
              applicationUnit: v.applicationUnit,
              applicationUnitId: v.applicationUnit?.id,
            }));
          }
        },
        afterAdd: (inputs) => builder.waterUsage.onAddInput(flatMap(inputs)),
      }}
      addSorter={(a, b) => builder.inputs.sorter(a, b)}
      columns={filterColumns<WorkOrderVariant>([
        {
          title: <FormattedMessage id="variants.entityName" />,
          dataIndex: "variant",
          render: (_, v) => formatVariantLink(v.variant),
        },
        !builder.isDataIntake &&
          currentTenant.features.inventoryStock && {
            width: "1rem",
            render: (_, v) => (
              <CurrentStockTooltip
                variant={v}
                date={workOrder.documentDate}
                localityId={workOrder.locality.id}
              />
            ),
          },
        !builder.isDataIntake && {
          title: <FormattedMessage id="dosage" />,
          dataIndex: "dosage",
          width: "22rem",
          align: "center",
          render: (_, _v, index) => (
            <RateInput
              name={["inputs", index, "dosage"]}
              inputIndex={index}
              onChange={() => builder.waterUsage.onInputChanged(index)}
            />
          ),
        },
        {
          title: builder.isDataIntake ? (
            <FormattedMessage id="workOrders.requested" />
          ) : (
            <FormattedMessage id="workOrders.totalRequested" />
          ),
          dataIndex: "totalAmount",
          width: "10rem",
          align: "center",
          render: (_, v, index) => (
            <Form.Field name={["inputs", index]}>
              {(input: WorkOrderVariant) => (
                <>
                  <div>
                    {formatUnitValueConversion(input.totalAmount, {
                      unit: input.unit,
                      conversionUnit: v.variant.variationUnit,
                      hideConversion: true,
                    })}
                  </div>
                  {builder.isDataIntake && (
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "75%" }}
                    >
                      {formatUnitValue(
                        v.dosage,
                        v.unit,
                        v.applicationUnit?.abbr
                      )}
                      <br />
                      {formatUnitValueConversion(
                        builder.inputs.getRequiredDosage(v),
                        {
                          unit: v.unit,
                          conversionUnit: v.variant.variationUnit,
                          hideConversion: true,
                          per: builder.allowProgress
                            ? workOrder.activity.progressUnit.abbr
                            : workOrder.locality.areaUnit.abbr,
                        }
                      )}
                    </Typography.Text>
                  )}
                </>
              )}
            </Form.Field>
          ),
        },
        builder.isDataIntake && {
          title: <FormattedMessage id="workOrders.consumed" />,
          align: "center",
          render: (_, v) => (
            <Form.Item noStyle shouldUpdate={shouldUpdate(["waterUsage"])}>
              {() => (
                <>
                  <div>
                    {formatUnitValueConversion(
                      builder.inputs.getTotalConsumed(v),
                      {
                        unit: v.unit,
                        conversionUnit: v.variant.variationUnit,
                        hideConversion: true,
                      }
                    )}
                  </div>
                  <Typography.Text type="secondary" style={{ fontSize: "75%" }}>
                    {formatUnitValueConversion(
                      builder.inputs.getActualDosage(v),
                      {
                        unit: v.unit,
                        conversionUnit: v.variant.variationUnit,
                        hideConversion: true,
                        per: builder.allowProgress
                          ? workOrder.activity.progressUnit.abbr
                          : workOrder.locality.areaUnit.abbr,
                      }
                    )}
                  </Typography.Text>
                </>
              )}
            </Form.Item>
          ),
        },
        !builder.isDataIntake &&
          builder.isSpraying && {
            title: (
              <FormattedMessage
                id="workOrders.spraying.perTank"
                defaultMessage="Per Tank"
              />
            ),
            width: "10rem",
            align: "center",
            render: (_, _v, index) => (
              <Form.Field name={["inputs", index]}>
                {(input: WorkOrderVariant) => (
                  <>
                    <Typography.Text>
                      {formatUnitValue(
                        input.totalAmount /
                          builder.waterUsage.value.tanksRequired,
                        input.unit
                      )}
                    </Typography.Text>
                  </>
                )}
              </Form.Field>
            ),
          },
        {
          title: (
            <FormattedMessage
              id={
                !currentTenant.inventoryRequestEnabled
                  ? "inventoryRequests.sourceWarehouse"
                  : "workOrders.inventoryStatus"
              }
            />
          ),
          width: 150,
          align: "center",
          render: (_, _v, index) => (
            <InventoryRequestWarehouseCell name={["inputs", index]} />
          ),
        },
      ])}
    />
  );
}
