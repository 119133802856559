import { FormattedMessage } from "react-intl";
import { TableInput } from "../../shared";
import { ItemKind, InventoryStatus, UnitType } from "../../../lib/graphql";
import {
  formatUnitValue,
  variantSelectDropdown,
  formatVariantLink,
  formatInventoryRequestLink,
} from "../../../lib/formats";

import { useContext } from "react";
import { useStockVariantOptions } from "../../../lib/hooks/inventory/variants";
import { Form, InputNumber, Rules } from "../../form";
import { isInputReadonly } from "./WorkOrderInputs";
import { WorkOrderContext } from "./WorkOrderContext";
import { WorkOrderVariant } from "./builder/tools";
import { ColumnsType } from "antd/lib/table";
import { useCurrentUser } from "../../../lib/hooks";
import { CurrentStockTooltip } from "./inventory";
import { filterFalse } from "../../../lib/utils";

export function WorkOrderTools({ readonly }: { readonly: boolean }) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();

  const columns: ColumnsType<WorkOrderVariant> = filterFalse([
    {
      title: <FormattedMessage id="items.entityName" />,
      dataIndex: ["variant", "id"],
      render: (_, v) => formatVariantLink(v.variant),
    },
    currentTenant.features.inventoryStock && {
      width: "1rem",
      render: (_, v) => (
        <CurrentStockTooltip
          variant={v}
          date={workOrder.documentDate}
          localityId={workOrder.locality.id}
        />
      ),
    },
    {
      title: (
        <FormattedMessage
          id="variants.qtyToAssign"
          defaultMessage="qtyToAssign"
        />
      ),
      dataIndex: ["variant", "totalAmount"],
      width: "15rem",
      render: (_, v, index) =>
        readonly || isInputReadonly(v) ? (
          formatUnitValue(v.totalAmount, v.unit)
        ) : (
          <Form.Item
            compact
            name={["tools", index, "totalAmount"]}
            rules={[Rules.gtEqZero, Rules.onlyIntegers]}
          >
            <InputNumber
              step={1}
              precision={v.unit.unitType == UnitType.Unitary ? 0 : undefined}
              min={0}
              addonAfter={v.unit.abbr}
            />
          </Form.Item>
        ),
    },
  ]);

  if (currentTenant.inventoryRequestEnabled) {
    columns.push({
      title: <FormattedMessage id="workOrders.inventoryStatus" />,
      width: 185,
      align: "center",
      dataIndex: "status",
      render: (_, v) => formatInventoryRequestLink(workOrder.id, v.status),
    });
  }

  return (
    <TableInput
      name="tools"
      tableProps={{
        bordered: true,
      }}
      dataSource={workOrder.tools}
      rowKey={(f) => f.variant.id}
      disabled={readonly}
      tableSelectProps={{
        mode: "multiple",
        optionsHook: useStockVariantOptions,
        optionsHookParams: {
          variables: {
            localityId: currentTenant.multifarmInventoryEnabled
              ? undefined
              : workOrder.locality.id,
            date: workOrder.documentDate,
            filter: { itemKind: [ItemKind.Reusable], token: false },
          },
        },
        placeholder: (
          <FormattedMessage id="select.variants" defaultMessage="variants" />
        ),
        dropdownRender: variantSelectDropdown({
          left: (
            <FormattedMessage
              id="variants.selectTools"
              defaultMessage="Name & Categories"
            />
          ),
          hideRight: !currentTenant.features.inventoryStock,
        }),
        entityById: (_, { variant }) => {
          if (variant) {
            return {
              id: "",
              variant,
              totalAmount: builder.tools.defaultAmount,
              status: InventoryStatus.NotRequested,
              unit: variant.variationUnit,
            };
          }
        },
      }}
      addSorter={(a, b) => builder.tools.sorter(a, b)}
      allowBulkRemove
      columns={columns}
    />
  );
}
