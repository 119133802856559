import { useState, useMemo } from "react";
import { SelectField, SelectFieldProps } from "../../form";
import { useCurrentLocale } from "../../../lib/hooks";
import { useEffectOnce } from "react-use";
import { captureException } from "@sentry/nextjs";

type Country = {
  code: string;
  name_en: string;
  name_es: string;
  emoji_flag: string;
};

export function CountrySelector(props: SelectFieldProps) {
  const { currentLocale } = useCurrentLocale();
  const [loading, setLoading] = useState(false);
  const [countriesData, setCountriesData] = useState<Country[]>([]);

  useEffectOnce(() => {
    const loadCountries = async () => {
      setLoading(true);

      try {
        const countriesModule = await import(
          "../../../lib/data/countries.json"
        );
        setCountriesData(countriesModule.default);
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    };

    loadCountries();
  });

  const options = useMemo(() => {
    if (countriesData.length === 0) return [];

    return countriesData.map((c) => {
      const countryName = c[`name_${currentLocale}`] || c.name_en;

      return {
        key: c.code,
        label: `${c.emoji_flag} ${countryName}`,
      };
    });
  }, [countriesData, currentLocale]);

  return <SelectField loading={loading} options={options} {...props} />;
}
