import { NewForm } from "../shared/ListView";
import { AddressInput, PhoneInput } from "../shared/Address";
import { FormattedMessage, useIntl } from "react-intl";
import { useCounterpartyCreate, useCurrentUser } from "../../lib/hooks";
import {
  CounterpartyFields,
  Counterparty,
  CountryFragment,
} from "../../lib/graphql";
import { Rules } from "../form";
import { formatTaxIDLabel } from "../../lib/formats";
import { useState } from "react";

interface NewCounterpartyFormProps {
  onClose(result?: Pick<Counterparty, "id">): void;
}

export function NewCounterpartyForm({ onClose }: NewCounterpartyFormProps) {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();

  const [country, setCountry] = useState<CountryFragment | undefined>(
    currentTenant.address?.country
  );

  return (
    <NewForm
      entityName={intl.formatMessage({ id: "counterparties.entityName" })}
      mutation={useCounterpartyCreate}
      onMutate={(result) => result.counterpartyCreate}
      mutationOptions={{ refetchQueries: ["Counterparties"] }}
      formValues={() => ({} as CounterpartyFields)}
      onClose={onClose}
      fields={[
        {
          label: <FormattedMessage id="displayName" />,
          name: "name",
          type: "string",
          rules: [Rules.required],
        },
        {
          label: <FormattedMessage id="counterparties.businessName" />,
          name: "businessName",
          type: "string",
          rules: [Rules.required],
        },
        {
          label: formatTaxIDLabel(country),
          name: "taxIdNumber",
          type: "string",
        },
        {
          label: <FormattedMessage id="internalId" />,
          name: "internalId",
          type: "string",
        },
        {
          name: "addressAttributes",
          label: <FormattedMessage id="address" />,
          formItemProps: { required: true },
          type: "custom",
          render: () => (
            <AddressInput
              name="addressAttributes"
              compact
              onCountryChange={setCountry}
            />
          ),
        },
        {
          name: "addressAttributes",
          key: "phone",
          label: <FormattedMessage id="phoneNumber" />,
          type: "custom",
          render: () => <PhoneInput name="addressAttributes" />,
        },
      ]}
    />
  );
}
