import { YoutubeFilled } from "@ant-design/icons";
import { Button, Modal, Spin, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player/lazy";
import { useCurrentLocale, useUserSettings } from "../../lib/hooks";
import { FormattedMessage } from "react-intl";
import { rtfValues } from "../../lib/formats";
import { useLocalStorage, useTimeoutFn } from "react-use";

interface VideoModalProps extends ReactPlayerProps {
  videoId: string;
}

export const VIDEO_TOUR_NAME = "videoTour";

export function VideoModal(props: VideoModalProps) {
  const { currentLocale } = useCurrentLocale();

  if (currentLocale !== "es") return null;

  return <VideoModalInner {...props} />;
}

export function VideoModalInner({ videoId, ...props }: VideoModalProps) {
  const localKey = `videoTour:${videoId}`;

  const { tourShown, update } = useUserSettings();

  const [open, setOpen] = useState(false);
  const [showTooltip, setShowToolip] = useState<boolean | undefined>(undefined);

  const [, , resetTooltipTimer] = useTimeoutFn(
    () => setShowToolip(false),
    3000
  );

  const showTooltipWithTimeout = useCallback(() => {
    setShowToolip(true);
    resetTooltipTimer();
  }, [setShowToolip, resetTooltipTimer]);

  const [localStorageValue, setLocalStorage] =
    useLocalStorage<boolean>(localKey);

  const shown = localStorageValue || tourShown(localKey);

  const onClose = useCallback(() => {
    if (!shown) showTooltipWithTimeout();

    setLocalStorage(true);

    update && update({ productTour: { [localKey]: true } });

    setOpen(false);

    localStorage?.removeItem(VIDEO_TOUR_NAME);
  }, [shown, localKey, update, setLocalStorage, showTooltipWithTimeout]);

  const onOpen = useCallback(() => {
    setShowToolip(undefined);
    setOpen(true);
  }, []);

  useEffect(() => {
    if (shown) return;

    // used to disable product tours
    localStorage?.setItem(VIDEO_TOUR_NAME, "true");

    setOpen(true);
  }, [shown]);

  return (
    <>
      <Tooltip
        placement="bottom"
        open={showTooltip}
        title={
          showTooltip ? (
            <FormattedMessage
              id="videos.watchAgain"
              defaultMessage="Want to watch the video again?{br}Click here"
              values={rtfValues}
            />
          ) : (
            <FormattedMessage
              id="videos.watchTutorial"
              defaultMessage="Watch tutorial video"
            />
          )
        }
      >
        <YoutubeFilled
          style={{ color: "#0077ff", cursor: "pointer" }}
          onClick={onOpen}
        />
      </Tooltip>

      <Modal
        open={open}
        centered={true}
        width={1024}
        closable={false}
        onCancel={onClose}
        footer={null}
        className="modal-curly"
        destroyOnClose
        style={{ textAlign: "center" }}
      >
        <div className="player-wrapper">
          {/* fallback is not working when player loaded, but video not */}
          <Spin size="large" className="react-player-loading" />

          <ReactPlayer
            url={`https://youtu.be/${videoId}`}
            playing
            controls
            width="100%"
            height="100%"
            className="react-player"
            {...props}
          />
        </div>

        <Button type="primary" onClick={onClose} style={{ marginTop: 16 }}>
          <FormattedMessage id="close" defaultMessage="Close" />
        </Button>
      </Modal>
    </>
  );
}
