import {
  QueryHookOptions,
  useQuery,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  UsersQuery,
  UsersDocument,
  UsersQueryVariables,
  UserUpdateMutation,
  UserUpdateMutationVariables,
  UserUpdateDocument,
  UserCreateMutation,
  UserCreateMutationVariables,
  UserCreateDocument,
  Permissions,
  MeQuery,
  MeQueryVariables,
  MeDocument,
  UserQuery,
  UserQueryVariables,
  UserDocument,
  ResetPasswordDocument,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables,
  SendResetPasswordDocument,
  SignInMutation,
  SignInMutationVariables,
  SignInDocument,
  SignOutMutation,
  SignOutMutationVariables,
  SignOutDocument,
  UserOptionsQuery,
  UserOptionsQueryVariables,
  UserOptionsDocument,
  UserDiscardMutation,
  UserDiscardMutationVariables,
  UserDiscardDocument,
  AcceptInviteMutation,
  AcceptInviteMutationVariables,
  AcceptInviteDocument,
  ResendInviteDocument,
  ResendInviteMutation,
  ResendInviteMutationVariables,
  SignUpDocument,
  SignUpMutation,
  SignUpMutationVariables,
  GenerateInvitationLinkMutation,
  GenerateInvitationLinkMutationVariables,
  GenerateInvitationLinkDocument,
  GenerateResetPasswordLinkMutation,
  GenerateResetPasswordLinkMutationVariables,
  GenerateResetPasswordLinkDocument,
} from "../../graphql";
import { useContext } from "react";
import { UserContext } from "../../../components/users/ContextProvider";
import { formatUsername } from "../../formats/users";
import { merge } from "lodash";

export function useCurrentUser() {
  return useContext(UserContext);
}

export function usePermissions<T>(selector: (permissions: Permissions) => T) {
  const { user } = useCurrentUser();
  const permissions = (user && user.permissions) || {};
  return selector(permissions);
}

export function useUserOptions(
  queryOptions?: QueryHookOptions<UserOptionsQuery, UserOptionsQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(
    UserOptionsDocument,
    queryOptions
  );

  const search = (val?: string) => {
    load(
      merge(
        {
          variables: { filter: { fullName: val } },
        },
        queryOptions
      )
    );
  };

  const options = data?.users?.items?.map((u) => ({
    key: u.id,
    email: u.email,
    label: formatUsername(u),
    user: u,
  }));

  return { load, search, loading, options };
}

export function useMe() {
  const { data, loading, refetch } = useQuery<MeQuery, MeQueryVariables>(
    MeDocument
  );
  return { me: data?.me, loading: loading, refetch };
}

export function useUsers(
  options?: QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const { loading, data } = useQuery(UsersDocument, options);

  if (!data || !data.users || !data.users.items) {
    return { loading };
  }

  const items = data.users.items;
  const totalCount = data.users.totalCount;

  return { loading, items, totalCount };
}

export function useUser(id: string) {
  const { data } = useQuery<UserQuery, UserQueryVariables>(UserDocument, {
    variables: { id },
    skip: !id || id === "",
  });

  return data && data.user;
}

export function useUserUpdate(
  options?: MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>
) {
  return useMutation(UserUpdateDocument, options);
}

export function useUserCreate(
  options?: MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>
) {
  return useMutation(UserCreateDocument, options);
}

export function useSignInUser(
  options?: MutationHookOptions<SignInMutation, SignInMutationVariables>
) {
  return useMutation(SignInDocument, options);
}

export function useSignUpUser(
  options?: MutationHookOptions<SignUpMutation, SignUpMutationVariables>
) {
  return useMutation(SignUpDocument, options);
}

export function useSignOutUser(
  options?: MutationHookOptions<SignOutMutation, SignOutMutationVariables>
) {
  return useMutation(SignOutDocument, options);
}

export function useResetPassword(
  options?: MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  return useMutation(ResetPasswordDocument, options);
}

export function useSendResetPassword(
  options?: MutationHookOptions<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >
) {
  return useMutation(SendResetPasswordDocument, options);
}

export function useUserDiscard(
  options?: MutationHookOptions<
    UserDiscardMutation,
    UserDiscardMutationVariables
  >
) {
  return useMutation(UserDiscardDocument, options);
}

export function useAcceptInvite(
  options?: MutationHookOptions<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >
) {
  return useMutation(AcceptInviteDocument, options);
}

export function useResendInvite(
  options?: MutationHookOptions<
    ResendInviteMutation,
    ResendInviteMutationVariables
  >
) {
  return useMutation(ResendInviteDocument, options);
}

export function useGenerateInvitationLink(
  options?: MutationHookOptions<
    GenerateInvitationLinkMutation,
    GenerateInvitationLinkMutationVariables
  >
) {
  return useMutation(GenerateInvitationLinkDocument, options);
}

export function useGenerateResetPasswordLink(
  options?: MutationHookOptions<
    GenerateResetPasswordLinkMutation,
    GenerateResetPasswordLinkMutationVariables
  >
) {
  return useMutation(GenerateResetPasswordLinkDocument, options);
}

export function useUserSettings() {
  const { user } = useCurrentUser();
  const [update] = useUserUpdate();

  const updateSettings = (settings: any) => {
    update({
      variables: {
        id: user?.id || "",
        changes: { settings: merge({}, user?.settings, settings) },
      },
    });
  };

  return {
    settings: user?.settings,
    tourShown: (key?: string) =>
      !user?.settings?.productTour?.force &&
      // `shown` is backfilled for old users
      (user?.settings?.productTour?.shown ||
        (key && user?.settings?.productTour?.[key])),
    update: updateSettings,
  };
}
