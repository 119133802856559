import { FormattedMessage } from "react-intl";
import { ColumnsType, TableInput } from "../../shared";
import {
  formatUnitValue,
  variantSelectDropdown,
  formatVariantLink,
} from "../../../lib/formats";
import { useContext, useState } from "react";
import { WorkOrderContext } from "./WorkOrderContext";
import { Form, InputNumber, Rules } from "../../form";
import { ItemKind, InventoryStatus, UnitType } from "../../../lib/graphql";
import { useStockVariantOptions } from "../../../lib/hooks/inventory/variants";
import { useCurrentUser, useItemSidebarContext } from "../../../lib/hooks";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import {
  CurrentStockTooltip,
  EditInventoryRequestSidebar,
  InventoryRequestWarehouseCell,
} from "./inventory";
import { WorkOrderVariant } from "./builder/tools";
import { filterFalse } from "../../../lib/utils";

export function WorkOrderUsedTools({ readonly }: { readonly: boolean }) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();
  const [editIndex, setEditIndex] = useState(0);
  const { setCurrentAction } = useItemSidebarContext();

  const columns: ColumnsType<WorkOrderVariant> = filterFalse([
    {
      title: <FormattedMessage id="items.entityName" />,
      dataIndex: ["variant", "id"],
      render: (_, v) => formatVariantLink(v.variant),
    },
    {
      title: builder.isInProgress ? (
        <FormattedMessage id="variants.qtyAssigned" defaultMessage="Assigned" />
      ) : (
        <FormattedMessage id="variants.returned" defaultMessage="Returned" />
      ),
      align: "center",
      width: "15rem",
      render: (_, v, index) =>
        v.status == InventoryStatus.NotRequested ? (
          <Form.Item
            compact
            name={["tools", index, "totalAmount"]}
            rules={[Rules.onlyIntegers]}
          >
            <InputNumber
              step={1}
              precision={v.unit.unitType == UnitType.Unitary ? 0 : undefined}
              min={0}
              addonAfter={v.unit.abbr}
              onChange={(val) =>
                builder.form.setFieldValue(
                  ["tools", index, "returnedAmount"],
                  val
                )
              }
            />
          </Form.Item>
        ) : (
          <>
            {formatUnitValue(v.totalAmount, v.unit)}
            {!readonly && (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setEditIndex(index);
                  setCurrentAction("editTool");
                }}
                style={{
                  position: "absolute",
                  right: "20px",
                  marginTop: "-5px",
                }}
              />
            )}
          </>
        ),
    },
    currentTenant.features.inventoryStock && {
      title: (
        <FormattedMessage
          id={
            !currentTenant.inventoryRequestEnabled
              ? "inventoryRequests.sourceWarehouse"
              : "workOrders.inventoryStatus"
          }
        />
      ),
      width: "15rem",
      align: "center",
      render: (_, _v, index) => (
        <InventoryRequestWarehouseCell name={["tools", index]} />
      ),
    },
  ]);

  if (!builder.isCompleted && currentTenant.features.inventoryStock) {
    columns.splice(1, 0, {
      width: "1rem",
      render: (_, v) => (
        <CurrentStockTooltip
          variant={v}
          date={workOrder.documentDate}
          localityId={workOrder.locality.id}
        />
      ),
    });
  }

  return (
    <>
      <EditInventoryRequestSidebar
        actionName="editTool"
        variant={builder.tools.getBy(editIndex)}
        onChanged={(value) => {
          builder.form.setFieldValue(["tools", editIndex], {
            ...value,
            returnedAmount: value.totalAmount,
          });
        }}
      />

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          prev.costCenters.filter(Form.undestroyed).length !==
          next.costCenters.filter(Form.undestroyed).length
        }
      >
        {() => {
          if (!readonly) {
            builder.tools.init();
          }

          return (
            <TableInput
              name="tools"
              tableProps={{
                bordered: true,
              }}
              dataSource={workOrder.tools}
              rowKey={(f) => f.variant.id}
              disabled={readonly}
              tableSelectProps={{
                mode: "multiple",
                optionsHook: useStockVariantOptions,
                optionsHookParams: {
                  variables: {
                    localityId: currentTenant.multifarmInventoryEnabled
                      ? undefined
                      : workOrder.locality.id,
                    date: workOrder.documentDate,
                    filter: {
                      itemKind: [ItemKind.Reusable],
                      token: false,
                    },
                  },
                },
                placeholder: (
                  <FormattedMessage
                    id="select.variants"
                    defaultMessage="variants"
                  />
                ),
                dropdownRender: variantSelectDropdown({
                  left: (
                    <FormattedMessage
                      id="variants.selectTools"
                      defaultMessage="Name & Categories"
                    />
                  ),
                  hideRight: !currentTenant.features.inventoryStock,
                }),
                entityById: (_, { variant }) => {
                  if (variant) {
                    return {
                      id: "",
                      variant,
                      totalAmount: builder.tools.defaultAmount,
                      status: InventoryStatus.NotRequested,
                      unit: variant.variationUnit,
                    };
                  }
                },
              }}
              addSorter={(a, b) => builder.tools.sorter(a, b)}
              allowBulkRemove
              columns={columns}
            />
          );
        }}
      </Form.Item>
    </>
  );
}
